import React from "react"

import "./CallToAction.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner/section-inner"
import ButtonLink from "../../components/button-link"

const CallToAction = ({ title, description, linkOne, linkTwo }) => {
  return (
    <Section className="CallToAction Section--vPadding">
      <SectionInner className="CallToAction-inner">
        <h2 className="Section-titleNotResponsive t-h2">{title}</h2>
        { description &&
          <div className="Section-text t-body2 u-textLeft u-applyListStyle hasLinkAfter" dangerouslySetInnerHTML={{ __html: description }}/>
        }
        <div className="CallToAction-buttonWrapper">
          {
            linkOne &&
            <ButtonLink className="CallToAction-button" to={linkOne.relativeUrl} title={linkOne.title} target={linkOne.target} variant={"contained"}/>
          }

          {
            linkTwo &&
            <ButtonLink className="CallToAction-button" to={linkTwo.relativeUrl} title={linkTwo.title} target={linkTwo.target} outlined={true}/>
          }
        </div>
      </SectionInner>
    </Section>
  )
}

export default CallToAction
