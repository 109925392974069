import PropTypes from "prop-types"
import React from "react"

import SectionInner from "../../components/section-inner"
import Section from "../../components/section"
import ProductCard from "../../components/product-card"
import Grid from "@material-ui/core/Grid"

import "./ArticleGrid.scss"
import { getCardMedia } from "../../helper"

const ArticleGrid = ({ articles, title, description }) => {
  return (
    <Section className="ArticleGrid Section--vPadding">
      <SectionInner className="ArticleGrid-inner">
        <h2 className="Section-title t-h2">{title}</h2>
        <div className="Section-text t-body2" dangerouslySetInnerHTML={{ __html: description }}/>
        <div className="ArticleGrid-articles CardGrid">
          <Grid container justify="flex-start" spacing={5} style={{ position: "relative", marginBottom: "40px" }}>
            {
                articles.map((articleItem, index) => {
                  if(!articleItem.article) {
                    return null
                  }

                  const data = {
                    ...articleItem,
                    title: articleItem.article.title,
                    description: articleItem.description,
                    articlePath: articleItem.article.fullSlug,
                    cardMedia: getCardMedia(articleItem.image),

                    // cardMedia: articleItem.image ?
                    //   {
                    //     url: articleItem.image.sourceUrl,
                    //     alt: (articleItem.image.altText !== "" ? articleItem.image.altText : articleItem.image.title),
                    //   }
                    //   :
                    //   { url: null, alt: "" },
                  }
                  return (
                    <Grid key={index} item xs={12} lg={6} xl={4}>
                      <ProductCard {...data} isArticle={true}/>
                    </Grid>
                  )
                },
              )
            }
          </Grid>
        </div>
      </SectionInner>
    </Section>

  )
}

ArticleGrid.propTypes = {
  articles: PropTypes.array,
}

export default ArticleGrid
