import PropTypes from "prop-types"
import React from "react"


import "./ProjectGrid.scss"
import LeftRightCard from "../../components/left-right-card"
import LeftRightGrid from "../../components/left-right-grid/left-right-grid"
import { getImage } from "../../helper"

const ProjectGrid = ({ projects, title, description, backgroundImage, isRound }) => {

  return (
    <LeftRightGrid title={title} description={description} backgroundImage={backgroundImage}>
      {
        projects.map((projectItem, index) => {

          const data = {
            ...projectItem,
            title: projectItem.title,
            content: projectItem.excerpt,
            link: projectItem.fullSlug,
            image: getImage(projectItem.featuredImage, isRound)
          }
          return (
            <LeftRightCard key={index} title={data.title} content={data.content} link={data.link} image={data.image} variant={index % 2 === 0 ? 'left' : 'right'} />
          )
        })
      }
    </LeftRightGrid>
  )
}

ProjectGrid.propTypes = {
  projects: PropTypes.array,
}

ProjectGrid.defaultProps = {
  isRound: false,
}

export default ProjectGrid
