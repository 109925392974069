import React from "react"

import "./TestimonialCarousel.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner/section-inner"
import TestimonialItem from "../../components/testimonial-item"
import Button from "@material-ui/core/Button"
import ArrowThinRight from "../../assets/svg/icons/arrow-thin-right.svg"
import ArrowThinLeft from "../../assets/svg/icons/arrow-thin-left.svg"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import _random from "lodash.random"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)


const TestimonialCarousel = ({ items, autoplay, interval }) => {
  const maxSteps = items.length

  const [activeStep, setActiveStep] = React.useState(_random(0, items ? (maxSteps - 1) : 0))

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
  }


  return (
    <Section className="TestimonialCarousel Section--vPadding">
      <SectionInner className="TestimonialCarousel-inner TestimonialCarousel-extraPadding ">

        <AutoPlaySwipeableViews
          className="TestimonialCarousel-carousel"
          index={activeStep}
          onChangeIndex={handleStepChange}
          interval={interval}
          autoplay={autoplay}
          springConfig={{duration: '0.5s', easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)', delay: '0s', tension: 300, friction: 30}}
          containerStyle={{alignItems: "center"}}
          enableMouseEvents
        >
          {items &&
            items.map((item, index) => {
              return <TestimonialItem key={index} name={item.name} company={item.company} text={item.text}/>
            })
          }
        </AutoPlaySwipeableViews>
        <div className="TestimonialCarousel-buttonWrapper">
          <Button href={null} className="TestimonialCarousel-button TestimonialCarousel-prev" size="medium" onClick={handleBack} disabled={activeStep === 0}>
            <ArrowThinLeft />
          </Button>
          <Button href={null} className="TestimonialCarousel-button TestimonialCarousel-next" size="medium" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            <ArrowThinRight />
          </Button>
        </div>

      </SectionInner>
    </Section>
  )
}

TestimonialCarousel.defaultProps = {
  autoplay: true,
  interval: 5000
}

export default TestimonialCarousel
