import PropTypes from "prop-types"
import React from "react"

import "./testimonial-item.scss"
import Divider from "@material-ui/core/Divider"

const TestimonialItem = ({ name, company, text }) => {
  return (
    <div className="TestimonialItem u-textCenter">
        <div className="t-quote" dangerouslySetInnerHTML={{__html: text}} />
        <Divider className="TestimonialItem-divider" />
        <p className="t-h4">{name}</p>
        <p>{company}</p>
    </div>
  )
}

TestimonialItem.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  text: PropTypes.string,
}

export default TestimonialItem
